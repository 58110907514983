@import "~antd/dist/antd.css";

:root {
  --bgSecondary: #f8f9fc;
  --lightBlue: #0071bd;
  --border: #d9d9d9;
}

.app {
  display: flex;
  flex-direction: column;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  width: 65%;
  margin: auto;
}

.main-container{
  width: 65%;
  margin: auto;
  padding-bottom: 50px;
}

.navbar-menu {
  width: 100%;
  justify-content: flex-end;
}

.logo a {
  color: white;
}

.logo :first-child {
  display: flex;
  align-items: center;
}

.logo-text {
  margin-left: 12px;
  font-size: 1.6em;
  font-weight: 500;
  color: #1890ff;
}

.empty-favorites-message{
  position: absolute;
  top: 45%;
  left: 45%;
}


.footer {
  background-color: #001529;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
}

.footer h5 {
  color: white;
  text-align: center;
  margin-top: 30px;
}

.home-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.show-more {
  margin-top: 0px !important;
}

.news-card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.news-card-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.news-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.news-category-selector {
  width: 300px;
  margin-top: 30px;
}

.crypto-card-body {
  display: flex;
  justify-content: space-between;
}

.crypto-card-daily-change {
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.favorite-icon{
  font-size: 20px;
  color: #188fffb2
}

.select-timeperiod {
  width: 200px !important;
  margin-top: 20px !important;
}
.coin-detail-container {
  margin: 30px;
}

.coin-heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid var(--border);
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 10px;
}

.coin-heading-container .coin-name {
  font-weight: 900;
  color: var(--lightBlue);
}

.coin-heading-container p {
  font-size: 1rem;
  opacity: 0.9;
  margin-bottom: 20px;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  color: #0071bd;
  margin-top: 20px;
}
.chart-title {
  color: #0071bd !important;
}
.price-container {
  display: flex !important;
  gap: 20px !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}
.price-change {
  font-weight: 900 !important;
}
.current-price {
  margin-top: 0px !important;
  font-weight: 900 !important;
}

.stats-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  margin-top: 30px;
}

.stats-container h2 {
  font-weight: 700;
  font-size: 1.4rem;
  margin-top: 20px;
  color: var(--lightBlue);
}

.coin-details-heading {
  font-weight: 700 !important;
  margin-top: 20px !important;
  color: var(--lightBlue) !important;
}

.coin-stats {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--border);
  font-size: 1rem;
  opacity: 0.9;
  padding: 20px;
}

.coin-stats-name {
  display: flex;
  gap: 10px;
  font-size: 1rem;
}

.stats {
  font-weight: 800;
}

.coin-value-statistics-heading p {
  font-size: 1rem;
  opacity: 0.9;
}

.coin-desc-link {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  padding-top: 20px;
}

.coin-desc-link h2 {
  font-weight: 700;
  color: var(--lightBlue);
}

.coin-desc-link p {
  font-size: 1rem;
  opacity: 0.9;
}

.coin-desc-link a {
  color: var(--lightBlue);
}

.coin-desc-link h3 {
  font-weight: 700;
}

.coin-desc {
  flex: 0.5;
}

.coin-links {
  padding: 0px 20px;
  flex: 0.5;
}

.coin-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border);
  padding: 20px;
}

.link-name {
  text-transform: capitalize;
  font-size: 1rem;
}

.coin-link a {
  color: var(--lightBlue);
  font-weight: 700;
  font-size: 1rem;
}

.coin-link:hover,
.coin-stats:hover {
  background-color: var(--bgSecondary);
}

.exchanges-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.search-bar {
  width: 400px;
  padding: 6px 20px;
  border-radius: 50px;
  font-size: 1.5em;
  color: rgba(2, 2, 77, 0.678);
}

.search-container{
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 30px;
}

.flex{
  display: flex;
}

.exchange-img{
  margin: 0 16px;
}

.menu-control-container {
  display: none !important;
  position: absolute !important;
  right: 10px !important;
  top: 25px !important;
  font-size: 1.2rem !important;
  background-color: var(--bgSecondary) !important;
  border: none !important;
}

.loader{
  height: 81vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.favorite-crypto-card-content{
   display: flex;
   justify-content: space-between;
   align-items: center;
}

@media screen and (max-width: 1650px) {
  .main-container, .navbar-content {
    width: 70%;
  }
}

@media screen and (max-width: 1280px) {
  .navbar-menu {
    position: fixed;
    z-index: 100;
    width: fit-content;
    right: 0;
    top: 64px;
  }

  .menu-control-container {
    display: flex !important;
    padding-top: 7px;
    margin-top: -8px !important;
  }

  .stats-container {
    flex-direction: column;
  }
  .coin-desc-link {
    flex-direction: column;
  }
  .stats-container h2 {
    margin-top: 0px;
  }
  .main-container, .navbar-content {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
  }

  .logo-text {
    font-size: 1.4em;
  }
}

@media screen and (max-width: 800px) {

  .home-title {
    font-size: 1.2rem !important;
  }
  .show-more {
    font-size: 1rem !important;
  }

  .search-bar {
    width: 350px;
    padding: 3px 13px;
  }
}

@media screen and (max-width: 500px) {
  .coin-links {
    padding: 0px;
  }
  .coin-detail-container {
    margin: 0;
  }
  .heading {
    margin-top: 20px;
  }
}
